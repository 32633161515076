import React from "react"
import PropTypes from 'prop-types';
import { withStyles, Typography, Grid, CardMedia, Card, CardContent } from "@material-ui/core";
import Layout from '../components/layout';
import Helmet from 'react-helmet';


const styles = theme => ({
    card: {
      display: "block",
      maxWidth: 320,
      textDecoration: "none",
      margin:"8px"
    },
    media: {
      height: 280,
    },
    cardTitle: {
      textTransform: "uppercase",
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: 600,
    }
});

function Content(props) {
    const {classes} = props;
    return (
        <Layout>
            <Helmet>
                <title>QHFS Limited</title>
            </Helmet>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={6}>
                    <p>QHFS Limited provides comprehensive and impartial financial advice to personal and corporate customers in their home or place of business.</p>
                    <p>We aim to ensure that our clients receive independent advice and an ongoing service that is objective, forward thinking and of the very highest standard.</p>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <img src="/images/home.jpg" alt=""/>
                </Grid>
            </Grid>

            <h2 id="services">Our Services</h2>
            
            <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={4}>
                    <Card href="/services/mortgages" className={classes.card} component="a">
                        <CardMedia image="/images/dog-sale.jpg" className={classes.media}/>
                        <CardContent>
                            <Typography component="h5" className={classes.cardTitle}>Mortgages</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card href="/services/investment" className={classes.card} component="a">
                        <CardMedia image="/images/dog-till.jpg" className={classes.media}/>
                        <CardContent>
                            <Typography component="h5" className={classes.cardTitle}>Savings &amp; Investments</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card href="/services/protection" className={classes.card} component="a">
                        <CardMedia image="/images/dog-sit.jpg" className={classes.media}/>
                        <CardContent>
                            <Typography component="h5" className={classes.cardTitle}>Protection</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card href="/services/retirement" className={classes.card} component="a">
                        <CardMedia image="/images/dog-football.jpg" className={classes.media}/>
                        <CardContent>
                            <Typography component="h5" className={classes.cardTitle}>Retirement</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card href="/services/tax" className={classes.card} component="a">
                        <CardMedia image="/images/dog-sheet.jpg" className={classes.media}/>
                        <CardContent>
                            <Typography component="h5" className={classes.cardTitle}>Tax</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Card href="/services/corporate" className={classes.card} component="a">
                        <CardMedia image="/images/dog-brochure.jpg" className={classes.media}/>
                        <CardContent>
                            <Typography component="h5" className={classes.cardTitle}>Corporate</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <p>Our clients value the service we provide and often recommend us to others, however, our written complaints procedure is available upon request.  In the event that you have a complaint we cannot settle, you may be entitled to refer it to the Financial Ombudsman Service (FOS).</p>

            <p>Further information about the FOS is available from their website <a href="https://www.financial-ombudsman.org.uk" target="_blank" rel="noopener noreferrer">www.financial-ombudsman.org.uk</a></p>
        </Layout>
    );
}

Content.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);